// libs
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
// modules
// import {isChild} from "./utils.js";

export class GSAPScroll
{
	constructor (elmt)
	{	
		this.speed = 0.08; //0.5
		this.elmt = elmt;
		this.leRaf = null;
		this.isWheeling = null;
		this.deltaY = 0;
		// this.ref;

	}

	init ()
	{
		this.scrollTop = 0;
		this.current = 0;

		
		this.height = document.querySelector(this.elmt).clientHeight-window.innerHeight;

		this.deplacement = gsap.quickSetter(this.elmt, "y", "px");

		
		this.addTicker = () => {
	      this.playTicker();
	    }
	    gsap.ticker.add(this.addTicker);
	}

	wheel()
	{

		window.addEventListener('wheel', this.ref = (e) => {
			// if(this.leRaf === null){
			// 	this.playRAF();
			// 	console.log('ii');
			// }

			// console.log('wheel init tourne');

			this.deltaY = e.deltaY;
			//console.log('WHEEL');
    		
    		window.clearTimeout( this.isWheeling );
            this.isWheeling = setTimeout( (e) => {
                this.deltaY = 0;
            }, 66);
        });
	}

	unwheel()
	{
		window.removeEventListener('wheel', this.ref);	
	}

	resize()
	{
		this.height = document.querySelector(this.elmt).clientHeight-window.innerHeight;
		ScrollTrigger.refresh();
	}

	playTicker(){
		//console.log('iiii');

		const dt = 1.0 - Math.pow(1.0 - this.speed, gsap.ticker.deltaRatio());

		//console.log(this.scrollTop);

		if(this.scrollTop + this.deltaY > this.height){
			this.scrollTop = this.height;
        }else if(this.scrollTop + this.deltaY < 0){
        	this.scrollTop = 0;
        }else if(this.deltaY !== 0){
        	this.scrollTop += this.deltaY;
        }

        this.current += (-this.scrollTop - this.current) * dt;
        this.deplacement(this.current);
        //console.log('current : ', this.current, 'scrollTop : ', this.scrollTop);
        
        ScrollTrigger.update();
	}

	destroy()
	{
		gsap.killTweensOf(this.elmt);
		window.removeEventListener('wheel', this.ref);
		gsap.ticker.remove(this.addTicker);
	}
}