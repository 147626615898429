// libs
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"

// import YouTubeToHtml5 from "@thelevicole/youtube-to-html5-loader";



// modules
import {GSAPScroll} from "./GSAPScroll";

// constants
import {scroll} from './constants';
import {indexInParent, isMobile} from './utils.js';



gsap.registerPlugin(ScrollTrigger);

let scroller;

if(!isMobile()){
    scroller = document.getElementById('garciScroll');
    ScrollTrigger.defaults({
        scroller: scroller
    });
}

const mouse = {};

let ecart;
let firstLoad = true;

mouse.x = 0;
window.addEventListener("mousemove", e => {    
    mouse.x = e.x;
    mouse.y = e.y;
});

if(!isMobile()){
    ScrollTrigger.scrollerProxy(scroller, {
      scrollTop(value) {
        if (arguments.length) {
          scroll.current = -value; // setter
        }
        return -scroll.current; // getter
      },
      getBoundingClientRect() {
        return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
      }
    });
}

export class Transition
{
	constructor(elmt) {
		this.currentStep = 0;
        this.allSteps = 2;

        this.newClass;
        this.newTitle;
        this.newDOM;

        this.animEnCours = true;


        // chargement img
        // this.len = 0;
        // this.counter = 0;
        // this.tableauImgs = [];
        // this.ancienLen = 0;
        // this.uneFois = true;

        
    }

    start (url) {

        //this.len = 0;
        //this.counter = 0;
        this.animEnCours = true;

    	this.animationSortie();
    	this.changementDOM(url);
    }

    // STEP
    animationSortie() {
        gsap.to('#main', {
            opacity:0,
            duration:0.5,
            ease:"power2.inOut",
            onComplete:() => {
                if(!isMobile()){
                    scroll.destroy();
                }
                this.incrementStep();
            }
        })
    }


    // STEP
    changementDOM(url) {
    	this.ajaxPromise(url).then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            let liste_class = doc.body.getAttribute('class');
            
            // maj titre de la page
            this.newTitle = doc.querySelector('title').innerHTML;
			this.newClass = doc.body.getAttribute('class');
            this.newDOM = doc.getElementById('main').innerHTML;

            // console.log('incr');
			this.incrementStep();
            // console.log('incrStep -> nouveau DOM OK');



            // if(doc.querySelectorAll('.preload').length !== 0){
            //     this.len = doc.querySelectorAll('.preload').length
            //     doc.querySelectorAll('.preload').forEach(x => {

            //         let limg = new Image();
            //         limg.src = x.getAttribute('src');

            //         // let doneLoading = limg.complete;
            //         // console.log(doneLoading, x);

            //         limg.onload = () => {
            //             console.log('gud', limg.src)
            //             this.counter++;
            //             this.uneImgChargee();
            //         }
            //     });
            // }



            // if(doc.querySelectorAll('.preload').length !== 0){

            //     var imgsProchainePage = [];
            //     doc.querySelectorAll('.preload').forEach(x => {
            //         imgsProchainePage.push(x.getAttribute('src'));
            //     });

            //     // console.log('une fois');
            //     this.chargementImages(imgsProchainePage);
            // }else{
            //     // console.log('else une fois');

            //     this.incrementStep();
            //     console.log('incrStep -> pas dimages a preload');
            // }
        });

        //history.pushState({}, '', url);
    }

    // uneImgChargee() {
    //     console.log(this.counter, this.len)
    //     if(this.counter == this.len){
    //         this.incrementStep();
    //         console.log('incrStep -> images chargees');
    //     }
    // }

    // ALL STEPS COMPLETE
    animationEntree(titre, classes, DOM) {

        // document.querySelectorAll('video').forEach(vid => {
        //     new YouTubeToHtml5({   
        //         selector: vid
        //     });
        // })

        gsap.to('body', {
            duration:0.5,
            backgroundColor:'#2C2727',
        })
        

        ecart = 236;
        if(window.innerWidth <= 1440){
            ecart = 181;
        }
        //reset
        // console.log('entree');
        window.scrollTo(0,0);

		this.currentStep = 0;
        // this.uneFois = true;
        if(!isMobile()){
            scroll.init();
            scroll.resize();
            scroll.destroy();
        }

        gsap.to('#main', {opacity:1, duration:0.5, ease:"power2.inOut", onComplete:() => {
            if(!isMobile()){
                scroll.init();
                scroll.resize();
                scroll.wheel();
            }
        }});

    	// maj titre, class, DOM page
        document.title = this.newTitle;
		document.body.setAttribute('class', this.newClass);
        document.getElementById('main').innerHTML = this.newDOM;

        // document.querySelectorAll('.preload').forEach(x => {
        //     console.log(x.getAttribute('src'));
        // });
        

        if(document.body.classList.contains('home')){

            if(document.querySelector('html').classList.contains('firstClick')){
                // new YouTubeToHtml5({
                //     withAudio: true
                // });
            }

            document.querySelectorAll('li').forEach(el =>{
                // console.log('HOME el');
                gsap.to(el, {
                    scrollTrigger:{
                        trigger: el,
                        start:'top bottom',
                        end:'bottom 85%',
                        scrub:true
                    },
                    blur:0,
                    scale:1,
                    ease:'none'
                })

                gsap.to(el, {
                    scrollTrigger:{
                        trigger: el,
                        start:'top 15%',
                        end:'bottom top',
                        scrub:true,
                    },
                    blur:15,
                    scale:0.9,
                    immediateRender:false,
                    ease:'none'
                })  

                ScrollTrigger.update();
            })
            

            let videoCorres;

            // hovers
            document.querySelectorAll('.titre').forEach(titre => {
                titre.addEventListener('mouseenter', (e) => {
                    e.target.classList.add('current')
                });
            });
            document.querySelectorAll('li').forEach(titre => {
                titre.addEventListener('mouseleave', (e) => {

                    if(!document.body.classList.contains('projetOuvert')){

                        // reset
                        gsap.killTweensOf('.preWords');
                        gsap.killTweensOf('.postWords');
                        gsap.killTweensOf('.titre.current .l')
                        gsap.killTweensOf(videoCorres)
                        gsap.killTweensOf(videoCorres.querySelector('.vignette'))

                        if(document.querySelector('.titre.current') !== null){
                            gsap.to('.titre.current .l', {
                                x:'0px',
                                ease:"power4.inOut",
                                duration:0.3
                            })
                        }

                        gsap.to(videoCorres, {
                            scaleX:0,
                            duration:0.2,
                            ease:"power4.inOut"
                        })
                        gsap.to(videoCorres.querySelector('.vignette'), {
                            scaleX:2.8,
                            duration:0.2,
                            ease:"power4.inOut"
                        })

                        // if(document.querySelector('.titre.current') !== null){
                        //     gsap.to(document.querySelector('.titre.current').parentNode.querySelector('.preWords'), {
                        //         x:'0',
                        //         ease:"power4.inOut",
                        //         duration:0.3
                        //     })
                        //     gsap.to(document.querySelector('.titre.current').parentNode.querySelector('.postWords'), {
                        //         x:'0',
                        //         ease:"power4.inOut",
                        //         duration:0.3
                        //     })
                        // }
                        gsap.to('.preWords', {
                            x:'0',
                            ease:"power4.inOut",
                            duration:0.3
                        })
                        gsap.to('.postWords', {
                            x:'0',
                            ease:"power4.inOut",
                            duration:0.3
                        })

                        e.target.querySelector('.titre').classList.remove('current', 'actif')
                    }
                });
            })
            document.querySelectorAll('.titre .l').forEach(letter => {
                letter.addEventListener('mouseenter', () => {
                    if(window.innerWidth > 767){
                        if(!letter.parentNode.classList.contains('actif')){
                            letter.parentNode.classList.add('actif');

                            let milieuLettre = letter.getBoundingClientRect().left + letter.offsetWidth/2;
                            videoCorres = letter.parentNode.parentNode.querySelector('.video');

                            gsap.killTweensOf(videoCorres);
                            gsap.killTweensOf(videoCorres.querySelector('.vignette'));

                            // gsap.set(videoCorres, {scale:0})
                            // gsap.set(videoCorres.querySelector('img'), {scaleX:2.8})
                            
                            // cote gauche de la lettre
                            
                                if(mouse.x < milieuLettre){

                                    gsap.set(videoCorres, {x: letter.getBoundingClientRect().left - videoCorres.offsetWidth/2 + "px"})

                                    for(let i = 0; i < indexInParent(letter); i++){
                                        gsap.to(document.querySelectorAll('.titre.current .l')[i], {
                                            x:'-' + ecart + 'px',
                                            ease:"power4.inOut",
                                            duration:0.6
                                        })
                                    }
                                    for(let i = indexInParent(letter); i < document.querySelectorAll('.titre.current .l').length; i++){
                                        gsap.to(document.querySelectorAll('.titre.current .l')[i], {
                                            x:ecart + 'px',
                                            ease:"power4.inOut",
                                            duration:0.6
                                        })
                                    }

                                // cote droit de la lettre
                                }else{

                                    gsap.set(videoCorres, {x: letter.getBoundingClientRect().left + letter.offsetWidth - videoCorres.offsetWidth/2 + "px"})

                                    for(let i = 0; i <= indexInParent(letter); i++){
                                        gsap.to(document.querySelectorAll('.titre.current .l')[i], {
                                            x:'-' + ecart + 'px',
                                            ease:"power4.inOut",
                                            duration:0.6
                                        })
                                    }
                                    for(let i = indexInParent(letter) + 1; i < document.querySelectorAll('.titre.current .l').length; i++){
                                        gsap.to(document.querySelectorAll('.titre.current .l')[i], {
                                            x:ecart + 'px',
                                            ease:"power4.inOut",
                                            duration:0.6
                                        })
                                    }
                                }
                            


                            gsap.to(videoCorres, {
                                scaleX:1,
                                duration:0.7,
                                delay:0.1,
                                ease:"power4.inOut"
                            })
                            gsap.to(videoCorres.querySelector('.vignette'), {
                                scaleX:1,
                                duration:0.7,
                                delay:0.1,
                                ease:"power4.inOut"
                            })

                            gsap.to(document.querySelector('.titre.current').parentNode.querySelector('.preWords'), {
                                x:'-' + ecart + 'px',
                                ease:"power4.inOut",
                                duration:0.6
                            })
                            gsap.to(document.querySelector('.titre.current').parentNode.querySelector('.postWords'), {
                                x:ecart + 'px',
                                ease:"power4.inOut",
                                duration:0.6
                            })
                        }
                    }
                    
                })
            });

        // about
        }else{

            document.querySelectorAll('.tracks').forEach(tr => {
                console.log(tr.offsetHeight)
                tr.setAttribute('data-height', tr.offsetHeight);
            })
            console.log(document.querySelectorAll('.tracks'))
            document.querySelectorAll('.tracks')[1].style.opacity = 0;
            document.querySelectorAll('.tracks')[1].style.height = 0;
            document.querySelectorAll('.tracks')[1].classList.add('ferme');
            document.querySelectorAll('.tracks')[0].classList.add('ouvert');

            // document.querySelectorAll('li').forEach(el =>{
            //     console.log('yooo')
            //     gsap.to(el, {
            //         scrollTrigger:{
            //             trigger: el,
            //             start:'top bottom',
            //             end:'bottom 85%',
            //             scrub:true
            //         },
            //         blur:0,
            //         scale:1,
            //         ease:'none'
            //     })

            //     gsap.to(el, {
            //         scrollTrigger:{
            //             trigger: el,
            //             start:'top 15%',
            //             end:'bottom top',
            //             scrub:true,
            //         },
            //         blur:15,
            //         scale:0.9,
            //         immediateRender:false,
            //         ease:'none'
            //     })  
            // })
        }

        ScrollTrigger.update();

        console.log('entr');
        if(firstLoad){
            console.log('first load');

            firstLoad = false;
            document.querySelector('#asplash').style.display = "flex";

            if(window.innerWidth > 767){

                gsap.to('.toL', {x:'-200px', ease:"power4.inOut", duration:2, delay:1, onComplete:() => {
                    if(!isMobile()){
                        scroll.unwheel();
                        scroll.scrollTop = 0;
                    }
                }})
                gsap.to('.toR', {x:'200px', ease:"power4.inOut", duration:2, delay:1})
                if(window.innerWidth > 1600){
                    gsap.to('.toP', {x:'-500px', ease:"power4.inOut", duration:2, delay:1})
                }else{
                    gsap.to('.toP', {x:'-360px', ease:"power4.inOut", duration:2, delay:1})
                }
                gsap.to('.trait', {scaleX:1, ease:"power4.inOut", duration:2, delay:1})
            }
            gsap.to('#asplash', {opacity:0, ease:"power4.inOut", duration:1, delay:3, onComplete:() => {
                document.getElementById('asplash').style.display = "none";
                if(!isMobile()){
                    scroll.wheel();
                }
            }})

            gsap.to('#asplash h1, #asplash .profil', {y:'-150%', ease:"power2.in", duration:1, delay:3})

            gsap.fromTo('.anim', {
                y: window.innerHeight + "px"
            }, {
                y:'0px',
                duration:2,
                delay:3,
                ease:"power2.out",
                onUpdate: () => {
                    ScrollTrigger.refresh();
                }
            })

        }
    }


    //
    // chargementImages(tableau){

    //     this.ancienLen = this.len;
    //     // pour tous les .preload du dom
    //     tableau.forEach((element) => {

    //         if(this.tableauImgs.includes(element) === false){
    //             this.tableauImgs.push(element);
    //             this.len++;

    //             //console.log(element);
    //             window['myImage'+this.len] = new Image();
    //             window['myImage'+this.len].src = element;
                
    //             window['myImage'+this.len].addEventListener( 'load', this.incrementCounter(), false );

    //         }

    //         // console.log(this.tableauImgs);
    //     });

    //     if(this.ancienLen === this.len){
    //         // console.log('pas de nouvelles images à charger');
    //         // console.log('length', this.tableauImgs.length);
    //         // console.log('INCRE STEPPPPPP AUTRE')
    //         this.incrementStep();
    //         console.log('incrStep -> pas de nouvelles images à charger');
    //     }
    // }

    // incrementCounter() {
    //     this.counter++;
    //     if ( this.counter === this.len && this.uneFois ) {
    //         // console.log('toutes les nouvelles images sont chargées');
    //         // console.log('length', this.tableauImgs.length);
    //         // console.log('INCRE STEPPPPPP FIN')
    //         this.incrementStep();
    //         console.log('incrStep -> nouvelles images à chargées');
    //         this.uneFois = false;
    //     }
    // }




    //
    //
    // util class
    //
    //
    incrementStep() {
    	this.currentStep++;
        // console.log(this.currentStep);
    	if(this.currentStep == this.allSteps) {
            // console.log('ça part');
    		this.animationEntree();
    	}
    }

    ajaxPromise (url) {
		return new Promise((resolve) => { //, reject
			const xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.onload = () => resolve(xhr.responseText);
			// xhr.onerror = () => reject(xhr.statusText);
			xhr.send();
		});
	}
}

(function() {
  const blurProperty = gsap.utils.checkPrefix("filter"),
        blurExp = /blur\((.+)?px\)/,
        getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

  gsap.registerPlugin({
    name: "blur",
    get(target) {
      return +(getBlurMatch(target)[1]) || 0;
    },
    init(target, endValue) {
      let data = this,
          filter = gsap.getProperty(target, blurProperty),
          endBlur = "blur(" + endValue + "px)",
          match = getBlurMatch(target)[0],
          index;
      if (filter === "none") {
        filter = "";
      }
      if (match) {
        index = filter.indexOf(match);
        endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
      } else {
        endValue = filter + endBlur;
        filter += filter ? " blur(0px)" : "blur(0px)";
      }
      data.target = target; 
      data.interp = gsap.utils.interpolate(filter, endValue); 
    },
    render(progress, data) {
      data.target.style[blurProperty] = data.interp(progress);
    }
  });
})();


raf();
function raf() {
    requestAnimationFrame(raf);
    if(document.querySelector('.etoile') != null){
        if(!isMobile()){
            gsap.set('.etoile', {rotation:scroll.current/2});
        }else{
            gsap.set('.etoile', {rotation:window.scrollY/2})
        }
    }
}