// libs
import gsap from "gsap"
// import ScrollTrigger from "gsap/ScrollTrigger"

// import YouTubeToHtml5 from "@thelevicole/youtube-to-html5-loader";

// modules
import {GSAPScroll} from "modules/GSAPScroll"
import {Transition} from "modules/Transition"
import {indexInParent, isMobile} from "modules/utils"

import {scroll} from 'modules/constants';

// gsap.registerPlugin(ScrollTrigger);

// const scroller = document.getElementById('garciScroll');
// ScrollTrigger.defaults({
//     scroller: scroller
// });

let firstclick = false;


window.addEventListener('resize', () => {
    if(!isMobile()){
        scroll.resize();
    }
})

window.addEventListener("DOMContentLoaded", () => {

    const transition = new Transition();

    document.addEventListener('click', (e) => {
        // console.log('animEnCours', animEnCours)
        // lien
        if (e.target.closest('.lien')) {
            e.preventDefault();
            transition.start(e.target.closest('.lien').getAttribute('href'));
            history.pushState({}, '', e.target.closest('.lien').getAttribute('href'));
        }



        if(!firstclick){
            firstclick = true;
            document.querySelector('html').classList.add('firstClick');
            // new YouTubeToHtml5({
            //     withAudio: true
            // });
        }

        if(e.target.closest('.titre')){

            gsap.to('.efface', {
                duration:0.5,
                opacity:0
            })

            gsap.to('body', {
                backgroundColor:'#000',
                duration:0.5
            })

            

            const liItem = e.target.closest('li');

            console.log(liItem);

            liItem.querySelector('video').currentTime = 0;
            liItem.querySelector('video').play();

            if(!isMobile()){
                scroll.unwheel();
                const dist = liItem.getBoundingClientRect().top - scroll.current - 0.4*window.innerHeight;
                scroll.scrollTop = dist;
            }else{
                const progress = {}
                progress.y = window.scrollY;
                const dist = e.target.getBoundingClientRect().top + window.scrollY - window.innerHeight/2 + 50;

                gsap.to(progress, {
                    duration:0.5,
                    y:dist,
                    ease:'power2.inOut',
                    onUpdate:() => {
                        window.scrollTo(0, progress.y)
                    },
                    onComplete:() => {
                        document.body.classList.add('hidd');
                    }
                })
                
                
            }

            document.body.classList.add('projetOuvert');

            document.querySelectorAll('li').forEach(li => {
                li.classList.add('none');
                
                if(li != liItem){
                    gsap.to(li, {
                        opacity:0,
                        duration:0.4,
                        onComplete: () => {
                            li.style.visibility = 'hidden'
                        }
                    })
                }
            })

            liItem.querySelector('.video').classList.add('notNone');

            gsap.to([liItem.querySelector('.titre'), liItem.querySelector('.postWords'), liItem.querySelector('.preWords')], {
                opacity:0,
                duration:0.4
            })

            if(window.innerWidth > 767){
                gsap.to(liItem.querySelector('.video'), {
                    x:-liItem.querySelector('.video').offsetWidth/2 + window.innerWidth/2 + "px",
                    scale:2,
                    duration:1.1,
                    delay:0.2,
                    ease:"power4.inOut"
                })
            }else{
                gsap.to(liItem.querySelector('.video'), {
                    opacity:1,
                    duration:0.5,
                    delay:0.3
                })
                // gsap.set(liItem.querySelector('.video'), {
                //     x:'-50%'
                // });
                // gsap.to(liItem.querySelector('.video'), {
                //     x:-liItem.querySelector('.video').offsetWidth/2 + window.innerWidth/2 + "px",
                //     // scale:2,
                //     duration:1.1,
                //     delay:0.2,
                //     ease:"power4.inOut"
                // })
            }

            gsap.to(liItem.querySelector('.video .vignette'), {
                opacity:0,
                duration:1.1,
                delay:0.2,
                onComplete:() =>{
                    liItem.querySelector('.video .vignette').style.display = "none";

                    gsap.to([liItem.querySelector('.close'), liItem.querySelector('.descri')], {
                        opacity:1
                    })
                },
                ease:"power4.inOut"
            })
        }else if(e.target.closest('.close')){

            document.body.classList.remove('hidd');

            gsap.fromTo('#volet', {
                y:'100%'
            },{
                y:'-100%',
                duration:1.6,
                ease:"power3.inOut"
            })

            gsap.delayedCall(0.8, () => {

                gsap.to('.efface', {
                    duration:0.5,
                    opacity:1
                })

                gsap.set('body', {
                    backgroundColor:'#2C2727',
                })

                gsap.set('.l, .preWords, .postWords, .video', {clearProps:'all'});

                const liItem = e.target.closest('li');

                liItem.querySelector('video').pause();

                // relancer smooth scroll
                if(!isMobile()){
                    scroll.wheel();

                    scroll.scrollTop = scroll.scrollTop + 100;
                    
                }

                document.querySelectorAll('.titre').forEach(el => {
                    el.classList.remove('current', 'actif');
                })

                document.querySelectorAll('li').forEach(li => {
                    li.classList.remove('none');
                    
                    li.style.visibility = 'initial'

                    gsap.to(li, {
                        opacity:1,
                        duration:0.4,
                    })
                })

                liItem.querySelector('.video').classList.remove('notNone');

                liItem.querySelectorAll('.l, .postWords, .preWords').forEach(el => {
                    el.setAttribute('style','');
                })

                
                gsap.set('.video', {scaleY:1});
                liItem.querySelector('.video').setAttribute('style','');
                liItem.querySelector('.titre').setAttribute('style','');
                liItem.querySelector('.video .vignette').setAttribute('style','');

                // gsap.to(liItem.querySelector('.titre'), {
                //     opacity:1,
                //     duration:0.4,
                // })
                // gsap.to(liItem.querySelector('.postWords'), {
                //     opacity:1,
                //     duration:0.4,
                //     delay:0.2,
                // })
                // gsap.to(liItem.querySelector('.preWords'), {
                //     opacity:1,
                //     duration:0.4,
                //     delay:0.2,
                //     onComplete:() => {
                //         document.body.classList.remove('projetOuvert');   
                //     }
                // })
                document.body.classList.remove('projetOuvert');

                
                gsap.set(liItem.querySelector('.video .vignette'), {
                    opacity:1,
                    // ease:"power4.inOut"
                })

                gsap.set([liItem.querySelector('.close'), liItem.querySelector('.descri')], {
                    opacity:0
                })
            });

            
        }else if(e.target.closest('.volet')){
            let el = e.target.closest('.volet').parentNode.querySelector('.tracks');

            if(el.classList.contains('ferme')){

                let autreEl = document.querySelector('.tracks.ouvert');

                gsap.to(el, {
                    height: el.getAttribute('data-height') + 'px',
                    ease:'power4.inOut',
                    duration:0.5,
                    delay:0.2
                })
                gsap.to(el, {
                    opacity: 1,
                    ease:"power4.in",
                    delay:0.2,
                    duration:0.5,
                    onComplete:() => {
                        el.classList.remove('ferme');
                        el.classList.add('ouvert');
                        scroll.resize();
                    }
                })

                gsap.to(autreEl, {
                    height: '0px',
                    ease:'power4.inOut',
                    duration:0.5,
                    delay:0.2,
                    onComplete:() => {
                        autreEl.classList.add('ferme');
                        autreEl.classList.remove('ouvert');
                        scroll.resize();
                    }
                })
                gsap.to(autreEl, {
                    opacity: 0,
                    duration:0.3,
                    ease:"power4.in",
                })
            }else{

                let autreEl = document.querySelector('.tracks.ferme');

                gsap.to(el, {
                    height: '0px',
                    ease:'power4.inOut',
                    duration:0.5,
                    delay:0.2,
                    onComplete:() => {
                        el.classList.add('ferme');
                        el.classList.remove('ouvert');
                        scroll.resize();
                    }
                })
                gsap.to(el, {
                    opacity: 0,
                    duration:0.3,
                    ease:"power4.in",
                })

                gsap.to(autreEl, {
                    height: autreEl.getAttribute('data-height') + 'px',
                    ease:'power4.inOut',
                    duration:0.5,
                    delay:0.2
                })
                gsap.to(autreEl, {
                    opacity: 1,
                    ease:"power4.in",
                    delay:0.2,
                    duration:0.5,
                    onComplete:() => {
                        autreEl.classList.remove('ferme');
                        autreEl.classList.add('ouvert');
                        scroll.resize();
                    }
                })
            }
        }

    });

    // proxy
    // ScrollTrigger.scrollerProxy(scroller, {
    //   scrollTop(value) {
    //     if (arguments.length) {
    //       scroll.current = -value; // setter
    //     }
    //     return -scroll.current; // getter
    //   },
    //   getBoundingClientRect() {
    //     return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
    //   }
    // });

    transition.start(window.location.href);

    

    // if(sessionStorage.getItem('start') == null) {
    //     sessionStorage.setItem('start', true);

    //     document.getElementById('asplash').style.opacity='1'

    //     gsap.to('.toL', {x:'-200px', ease:"power4.inOut", duration:2, delay:1})
    //     gsap.to('.toR', {x:'200px', ease:"power4.inOut", duration:2, delay:1})
    //     gsap.to('.toP', {x:'-300px', ease:"power4.inOut", duration:2, delay:1})
    //     gsap.to('.trait', {scaleX:1, ease:"power4.inOut", duration:2, delay:1, onComplete:() => {
    //         document.getElementById('asplash').classList.add('pointer');
    //     }})
    //     gsap.to('#asplash', {opacity:0, ease:"power4.inOut", duration:1, delay:3})

    //     gsap.fromTo('#garciScroll', {
    //         y:'100%'
    //     },{
    //         y:'0%',
    //         ease:'power4.out',
    //         duration:2,
    //         delay:3,
    //         immediateRender:false,
    //         onComplete:() => {
    //             document.getElementById('asplash').style.display="none"
    //             scroll.init();
    //             scroll.wheel();
    //         }
    //     })
    // }else{
    //     //temp
    //     document.getElementById('asplash').style.display="none"
    //     scroll.init();
    //     scroll.wheel();

    //     setTimeout(function() {
    //         scroll.resize();
    //     }, 1000)
    // }

    // // gsap.to('.allProj', {
    // //     scrollTrigger:{
    // //         trigger: '.allProj',
    // //         start:'top top',
    // //         end:'bottom bottom',
    // //         scrub:true
    // //     },
    // //     blur: 10,
    // //     ease:'none'
    // // })


    // if(document.body.classList.contains('home')){
    //     document.querySelectorAll('li').forEach(el =>{
    //         gsap.to(el, {
    //             scrollTrigger:{
    //                 trigger: el,
    //                 start:'top bottom',
    //                 end:'bottom 85%',
    //                 scrub:true
    //             },
    //             blur:0,
    //             scale:1,
    //             ease:'none'
    //         })

    //         gsap.to(el, {
    //             scrollTrigger:{
    //                 trigger: el,
    //                 start:'top 15%',
    //                 end:'bottom top',
    //                 scrub:true,
    //             },
    //             blur:15,
    //             scale:0.9,
    //             immediateRender:false,
    //             ease:'none'
    //         })  
    //     })
    // }else if(document.body.classList.contains('about')){
    //     raf();
    //     function raf() {
    //         requestAnimationFrame(raf);
    //         gsap.set('.etoile', {rotation:scroll.current/2});
    //     }

    //     document.querySelectorAll('li').forEach(el =>{
    //         gsap.to(el, {
    //             scrollTrigger:{
    //                 trigger: el,
    //                 start:'top bottom',
    //                 end:'bottom 85%',
    //                 scrub:true
    //             },
    //             blur:0,
    //             scale:1,
    //             ease:'none'
    //         })

    //         gsap.to(el, {
    //             scrollTrigger:{
    //                 trigger: el,
    //                 start:'top 15%',
    //                 end:'bottom top',
    //                 scrub:true,
    //             },
    //             blur:15,
    //             scale:0.9,
    //             immediateRender:false,
    //             ease:'none'
    //         })  
    //     })
    // }




    // // click event
    // document.addEventListener('click', function (e) {
    //     if(e.target.closest('body')){
    //         scroll.resize();
    //         ScrollTrigger.refresh();
    //     }

    //     if(!firstclick){
    //         firstclick = true;
    //         new YouTubeToHtml5({
    //             withAudio: true
    //         });
    //     }

    //     if(e.target.closest('.titre')){

    //         console.log('ici');

    //         const liItem = e.target.parentNode;

    //         liItem.querySelector('video').currentTime = 0;
    //         liItem.querySelector('video').play();
    //         scroll.destroy();

    //         const dist = liItem.getBoundingClientRect().top - scroll.current - 0.4*window.innerHeight;
    //         scroll.scrollTop = dist;

    //         document.body.classList.add('projetOuvert');

    //         document.querySelectorAll('li').forEach(li => {
    //             li.classList.add('none');
                
    //             if(li != liItem){
    //                 gsap.to(li, {
    //                     opacity:0,
    //                     duration:0.4,
    //                 })
    //             }
    //         })

    //         liItem.querySelector('.video').classList.add('notNone');

    //         gsap.to([liItem.querySelector('.titre'), liItem.querySelector('.postWords'), liItem.querySelector('.preWords')], {
    //             opacity:0,
    //             duration:0.4
    //         })

    //         gsap.to(liItem.querySelector('.video'), {
    //             x:-liItem.querySelector('.video').offsetWidth/2 + window.innerWidth/2 + "px",
    //             scale:2,
    //             duration:1.1,
    //             delay:0.2,
    //             ease:"power4.inOut"
    //         })

    //         gsap.to(liItem.querySelector('.video .vignette'), {
    //             opacity:0,
    //             duration:1.1,
    //             delay:0.2,
    //             onComplete:() =>{
    //                 liItem.querySelector('.video .vignette').style.display = "none";

    //                 gsap.to([liItem.querySelector('.close'), liItem.querySelector('.descri')], {
    //                     opacity:1
    //                 })
    //             },
    //             ease:"power4.inOut"
    //         })
    //     }else if(e.target.closest('.close')){

            
    //         gsap.set('.l, .preWords, .postWords, .video', {clearProps:'all'});

    //         const liItem = e.target.closest('li');
    //         console.log('et ici', liItem);

    //         liItem.querySelector('video').pause();

    //         // relancer smooth scroll
    //         scroll.wheel();

    //         document.querySelectorAll('.titre').forEach(el => {
    //             el.classList.remove('current', 'actif');
    //         })

    //         document.querySelectorAll('li').forEach(li => {
    //             li.classList.remove('none');
                

    //             gsap.to(li, {
    //                 opacity:1,
    //                 duration:0.4,
    //             })
    //         })

    //         liItem.querySelector('.video').classList.remove('notNone');

    //         liItem.querySelectorAll('.l, .postWords, .preWords').forEach(el => {
    //             el.setAttribute('style','');
    //         })

            
    //         gsap.set('.video', {scaleY:1});
    //         liItem.querySelector('.video').setAttribute('style','');
    //         liItem.querySelector('.titre').setAttribute('style','');
    //         liItem.querySelector('.video .vignette').setAttribute('style','');

    //         // gsap.to(liItem.querySelector('.titre'), {
    //         //     opacity:1,
    //         //     duration:0.4,
    //         // })
    //         // gsap.to(liItem.querySelector('.postWords'), {
    //         //     opacity:1,
    //         //     duration:0.4,
    //         //     delay:0.2,
    //         // })
    //         // gsap.to(liItem.querySelector('.preWords'), {
    //         //     opacity:1,
    //         //     duration:0.4,
    //         //     delay:0.2,
    //         //     onComplete:() => {
    //         //         document.body.classList.remove('projetOuvert');   
    //         //     }
    //         // })
    //         document.body.classList.remove('projetOuvert');

            
    //         gsap.set(liItem.querySelector('.video .vignette'), {
    //             opacity:1,
    //             // ease:"power4.inOut"
    //         })

    //         gsap.set([liItem.querySelector('.close'), liItem.querySelector('.descri')], {
    //             opacity:0
    //         })

            
    //     }
    // });

    // let videoCorres;

    // // hovers
    // document.querySelectorAll('.titre').forEach(titre => {
    //     titre.addEventListener('mouseenter', (e) => {
    //         e.target.classList.add('current')
    //     });
    // });
    // document.querySelectorAll('li').forEach(titre => {
    //     titre.addEventListener('mouseleave', (e) => {

    //         if(!document.body.classList.contains('projetOuvert')){

    //             // reset
    //             gsap.killTweensOf('.preWords');
    //             gsap.killTweensOf('.postWords');
    //             gsap.killTweensOf('.titre.current .l')
    //             gsap.killTweensOf(videoCorres)
    //             gsap.killTweensOf(videoCorres.querySelector('.vignette'))

    //             if(document.querySelector('.titre.current') !== null){
    //                 gsap.to('.titre.current .l', {
    //                     x:'0px',
    //                     ease:"power4.inOut",
    //                     duration:0.3
    //                 })
    //             }

    //             gsap.to(videoCorres, {
    //                 scaleX:0,
    //                 duration:0.2,
    //                 ease:"power4.inOut"
    //             })
    //             gsap.to(videoCorres.querySelector('.vignette'), {
    //                 scaleX:2.8,
    //                 duration:0.2,
    //                 ease:"power4.inOut"
    //             })

    //             // if(document.querySelector('.titre.current') !== null){
    //             //     gsap.to(document.querySelector('.titre.current').parentNode.querySelector('.preWords'), {
    //             //         x:'0',
    //             //         ease:"power4.inOut",
    //             //         duration:0.3
    //             //     })
    //             //     gsap.to(document.querySelector('.titre.current').parentNode.querySelector('.postWords'), {
    //             //         x:'0',
    //             //         ease:"power4.inOut",
    //             //         duration:0.3
    //             //     })
    //             // }
    //             gsap.to('.preWords', {
    //                 x:'0',
    //                 ease:"power4.inOut",
    //                 duration:0.3
    //             })
    //             gsap.to('.postWords', {
    //                 x:'0',
    //                 ease:"power4.inOut",
    //                 duration:0.3
    //             })

    //             e.target.querySelector('.titre').classList.remove('current', 'actif')
    //         }
    //     });
    // })

    
});







































